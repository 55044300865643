<template>
  <div id="mian" class="clear">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>商户名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="商户名称/编号"
          ></el-input>
        </div>
        <div class="item">
          <i>审核状态</i>
          <el-select
            v-model="query.status"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="已创建" value="P1000"></el-option>
            <el-option label="审核中" value="P2000"></el-option>
            <el-option label="已驳回" value="P3000"></el-option>
            <el-option label="审核通过" value="P4000"></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>商户状态</i>
          <el-select
            v-model="query.mchStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="待签约" value="P1000"></el-option>
            <el-option label="激活" value="P2000"></el-option>
            <el-option label="冻结" value="P3000"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>

        </div>
        <div class="itembtn">
          <el-button type="primary" class="searchBt" @click="onSearch"
            >查询</el-button
          >
          <el-button
            v-auth="'ELECTRLED:MCHCOM:MCHLIST/ADD'"
            @click="addclickHander"
            class="outputBt"
            >新增</el-button
          >
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td style="width: 192px">
            <p>创建时间</p>
          </td>
          <td style="width: 220px">
            <p>商户名称</p>
          </td>
          <td style="width: 130px">
            <p>商户编号</p>
          </td>
          <td style="width: 140px">
            <p>审核状态</p>
          </td>
          <td style="width: 75px">
            <p>商户状态</p>
          </td>
          <td style="width: 93px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td style="width: 192px">
            <p>{{ v.createTime }}</p>
          </td>
          <td style="width: 220px">
            <p>{{ v.mchName }}</p>
          </td>
          <td style="width: 130px">
            <p>{{ v.mchIdHj }}</p>
          </td>
          <td style="width: 140px">
            <p>
              {{ shenheStatus[v.status] }}
            </p>
          </td>
          <td style="width: 75px">
            <p>
              {{ mchStatusData[v.mchStatus] }}
            </p>
          </td>
          <td style="width: 93px">
            <p>
              <i
                v-auth="'ELECTRLED:MCHCOM:MCHLIST/DETAIL'"
                class="lianjie"
                @click="
                  $router.push({
                    name: 'ledger_detail_jbxx',
                    query: { id: v.mchId },
                  })
                "
                >详情</i
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
  </div>
</template>
<script>
import { ledgerLists } from "@/api/user/shopManager.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      mchStatusData:{//商户状态
        "P1000":"待签约",
        "P2000":"激活",
        "P3000":"冻结",
      },
      shenheStatus:{//审核状态
        "P1000":"已创建",
        "P2000":"审核中",
        "P3000":"已驳回",
        "P4000":"审核通过",
      },
      query: {
        pageNo: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        status:"",
        mchStatus: "",
        keyword: "",
      },
      tabData: [],
      totalNum: 0,
    };
  },
  computed: {
    ...mapState({
      ledgerDetail: "user_ledgerQuery",
    }),
  },
  created() {
    if (this.ledgerDetail) {
      this.query = this.ledgerDetail.query;
    }
    this.getLists(false);
    this.$enter(this.$route.path, this.onSearch);
  },
  methods: {
    ...mapMutations({
      LedgerQuery: "user_setLedgerQuery",
      setAddLedger: "user_setAddLedger",
    }),
    //新增按钮
    addclickHander() {
      this.setAddLedger(null);
      this.$router.push("/user_yh/ledger/add_jbxx");
    },
    // 获取列表
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      ledgerLists(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData.merchants;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    // 搜索
    onSearch() {
      this.getLists();
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.LedgerQuery(data);
  },
};
</script>
<style scoped>

</style>
